import { useTranslation } from 'next-i18next'

import { css } from '@emotion/react'

import Image from '@/components/common/Image'

import { mediaUp } from '@/styles/media'

function PartnersAe() {
  const { t } = useTranslation('common')

  return (
    <>
      <li css={partner}>
        <img
          src={'https://cdn.naviparking.com/digital_parking/img/telr.webp'}
          alt={t('partner.telr')}
        />
      </li>
      <li css={partner}>
        <img
          src={'https://cdn.naviparking.com/digital_parking/img/visa.webp'}
          alt="partner.visa"
        />
      </li>
      <li css={partner}>
        <img
          src={'https://cdn.naviparking.com/digital_parking/img/mc.webp'}
          alt={t('partner.masterCard')}
        />
      </li>
      <li css={partner}>
        <img
          src={
            'https://cdn.naviparking.com/digital_parking/img/verified-by-visa.webp'
          }
          alt={t('partner.verifiedByVisa')}
        />
      </li>
      <li css={partner}>
        <img
          src={'https://cdn.naviparking.com/digital_parking/img/mc-sc.webp'}
          alt={t('partner.mcSc')}
        />
      </li>
      <li css={partner}>
        <img
          src={
            'https://cdn.naviparking.com/digital_parking/img/ssk-technologies.webp'
          }
          alt={t('partner.sskTechnologies')}
        />
      </li>
      <li css={partner}>
        <img
          src={
            'https://cdn.naviparking.com/digital_parking/img/union-pay.svg.webp'
          }
        />
      </li>
    </>
  )
}

const partner = css`
  position: relative;
  margin: 10px 14px;

  ${mediaUp('md')} {
    margin: 0 14px;
  }

  ${mediaUp('lg')} {
    margin: 0 53px;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  &:nth-of-type(1) {
    height: 20px;
    width: 41px;

    ${mediaUp('lg')} {
      height: 43px;
      width: 92px;
    }
  }

  &:nth-of-type(2) {
    height: 15px;
    width: 46px;

    ${mediaUp('lg')} {
      height: 33px;
      width: 103px;
    }
  }

  &:nth-of-type(3) {
    height: 24px;
    width: 32px;

    ${mediaUp('lg')} {
      height: 54px;
      width: 71px;
    }
  }

  &:nth-of-type(4) {
    height: 24px;
    width: 56px;

    ${mediaUp('lg')} {
      height: 54px;
      width: 125px;
    }
  }

  &:nth-of-type(5) {
    height: 20px;
    width: 65px;

    ${mediaUp('lg')} {
      height: 44px;
      width: 145px;
    }
  }

  &:nth-of-type(6) {
    height: 27px;
    width: 75px;

    ${mediaUp('lg')} {
      height: 61px;
      width: 167px;
    }
  }

  &:nth-of-type(7) {
    height: 25px;
    width: 42px;

    ${mediaUp('lg')} {
      height: 45px;
      width: 95px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export default PartnersAe
